import React from 'react'

export default function MentionsLegales() {
  return (
    <div id="MentionsLegales">
      <h2>Mentions légales</h2>
      <div>
      <p>Le site rapportannuel-assurancemaladieparis.fr a pour vocation de présenter les événements et les grands projets qui ont marqué l’activité de l’Assurance Maladie de Paris en 2020 dans ces différents domaines d’intervention.<br/>Ce site est édité par le département Communication de l’Assurance Maladie de Paris dont le siège social est situé au 21 Rue Georges Auric, 75019 Paris.</p>

          <h3 className="mb-0">Directeur de la publication</h3>
          <p>Département Communication de l’Assurance Maladie de Paris</p>

          <h3 className="mb-0">Hébergement</h3>
          <p>HaiSoft SARL<br/>
          7 rue Vieille Levée<br/>
          45100 Orléans, France<br/>
          SIRET : 451475651 00035<br/>
          TVA Intra : FR57 451475651<br/>
          Capital social : 27 000€</p>

          <h3 className="mb-0">Programmation / Intégration graphique</h3>
          <p>Charlieprod<br/>
          2 rue de la République<br/>
          93 100 Montreuil<br/>
          SIRET : 453 699 712 00023<br/>
          Capital social : 7 500 €</p>


          <h3 className="mb-0">Crédit photographique</h3>
          <p>Cnam<br/>
          CPAM de Paris<br/>
          Adobe Stock</p>




          <h3 className="mb-0">Données personnelles</h3>
          <p>La mise en œuvre du site rapportannuel-assurancemaladieparis.fr par la Cpam nécessite le traitement de données à caractère personnel. Les données ne sont pas conservées au-delà de la période d’activité des personnes concernées.<br/>
          Vous disposez d’un droit d’accès et de rectification des données vous concernant que vous pouvez exercer auprès du Département de la communication.</p>


          <p>Des informations sont extraites pour la gestion : l'origine géographique des requêtes, la date et l'heure de la connexion.<br/>
          Google utilise ces informations pour le compte de la CPAM Paris afin d’évaluer votre utilisation de la page web, d’établir des comptes rendus sur l’activité des pages web et de fournir des services supplémentaires. L’adresse IP transmise par votre navigateur dans le cadre de Google Analytics n’est pas combinée à d’autres données de Google.</p>

          <p>Pour la réalisation d’analyses statistiques, et non pour des raisons commerciales, des cookies sont présents sur le site. Les cookies utilisés sur le site sont les suivants : _gat, _ga_, _gid.<br/>
          Ils sont paramétrables à l'ouverture du site via une fenêtre en bas de l'écran et via le lien "Gestion des cookies".<br/>
          Le gestionnaire de cookies utilise lui-même un cookie afin de sauvegarder l'option choisie (refus des cookies / acceptation des cookies), ce dernier ne collecte et n'utilise pas de données personnelles.</p>


          <h3 className="mb-0">Propriété intellectuelle</h3>
          <p>Le site rapportannuel-assurancemaladieparis.fr relève de la réglementation applicable à la propriété intellectuelle.<br/>
          Pour tous les contenus, textes et logos présentés sur le site : tous droits d'auteur des œuvres sont réservés.</p>

          <p>Pour toute autre autorisation, la demande doit être adressée à l'adresse suivante :<br/>
          CPAM de Paris<br/>
          Département de la communication<br/>
          21 Rue Georges Auric,<br/>
          75019 Paris</p>
      </div>
    </div>
  )
}
