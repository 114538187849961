import React, { useState} from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Components from '../../../Components';

const Tab = ({ index, name, activeTab, changeActiveTab }) => {
  return (
    <li className={index === activeTab && "is-active"} onClick={() => changeActiveTab(index)}>
      <a>
        <span dangerouslySetInnerHTML= {{__html: name }} />
      </a>
    </li>
  );
};


export default function CieTabs({ titles, component, tabsClassName, className}) { 
  const [ activeTab, setActiveTab ] = useState(0);
  const changeActiveTab = (name) => {
    setActiveTab(name)
  }
  const timeout = { enter: 1200, exit: 500 };

  return (
    <>
      <div className={`tabs is-toggle is-toggle-rounded ${ tabsClassName ? tabsClassName : ''}`}>
        <ul>
        {
          titles?.length > 0 && titles.map((title, index) => {
            return (
              <Tab name={ title } index= { index } activeTab={ activeTab } changeActiveTab={ changeActiveTab } />
            )
          })
        }
        
        </ul>
      </div>
      <CSSTransition
        className={`tabs-content ${ className ? className : '' }`}
        timeout={1200}
        classNames="fade"
        timeout={ timeout }
      >
          <div>
            { 
            component?.length > 0 &&
              component.map((c, i) => (
                  i === activeTab && <Components
                      component={c}
                  />
              ))
          
          }
          {
            /*
            activeTab ?
              contents[activeTab]
              :
              contents[0]
              */
          }
          </div>
      </CSSTransition>
      
    </>
  )
}
