import React, { useState } from 'react'

export default function Table({ className, content, moreOff, key, contentBefore, contentAfter }) {
  const [ active, setActive ] = useState(false);
  const handleClick = () => {
    setActive(!active);
  }
  const btClassName = active ? 'bt_up' : 'bt_down';
  return (
    <>
      { contentBefore ? <div dangerouslySetInnerHTML={{ __html: contentBefore }} /> : '' }
      <div key={ key ? key : null } className={`table-container ${ active || moreOff ? 'active' : ''}`}>
        <table className={`table ${className ? className : ''}`} dangerouslySetInnerHTML = {{  __html: content }} />
      </div>
      {
          !moreOff && <button onClick={ handleClick } className={`table_container__button cie_radius0 cie_w99 bg_white_imp ${ btClassName }`} ><span>&nbsp;</span></button>
      } 
      { contentAfter ? <div dangerouslySetInnerHTML={{ __html: contentAfter }} /> : '' }
    </>
  )
}
