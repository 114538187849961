import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard"; 

const AdsConseillers = () => {
  return (
    <div className="cie_nbanimate cie_focus has-text-weight-bold">
      <div className="card">
        <div className="card-content ">
          <NumberAnimateNocard  nb={ 10 } suffix=" <span class='has-text-weight-normal has-text-centered'>conseillers</span><span class='size-7 has-text-weight-normal has-text-centered' >CPAM de Paris ont ainsi mené</span>" />
          <NumberAnimateNocard className="mt-5"  nb={ 1490 }  suffix="<span class='has-text-weight-normal has-text-centered'>accompagnements avec soins</span><span class='size-7 has-text-weight-normal has-text-centered' >réalisés au 31 décembre 2020</span>" />
          <NumberAnimateNocard className="mt-5"  nb={ 1209 } suffix=" <span class='has-text-weight-normal has-text-centered'>accompagnements étaient encore en cours.</span>" />
        </div>
      </div>
    </div>
  )
}
export default AdsConseillers;