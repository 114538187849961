import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const NumberAnimate = ({ key, nb, className, cardClassName, noCard, prefix, suffix, afterNb, delay, duration, decimals },
    isVisible 
) => {
    const [visibility, setVisibility] = useState(false);
 
    return ( 
		<CountUp 
				key = { key && key }
                start = { visibility ? nb / 100 : null }
                end = { nb }
                delay = { delay ? delay : 1 }
                duration = { duration ? duration : 0.75 }
                separator = " "
                decimals = { decimals ? decimals : 0 }
                decimal = ","
                prefix = {`${ prefix ? prefix : "" }<h4>`}
                suffix = {`${ afterNb ? afterNb : '' }</h4>${ suffix ? suffix : "" } `}
            >
            { 
            ({ countUpRef }) => ( 
            <VisibilitySensor partialVisibility onChange = {
                    (isVisible) => {
                        setVisibility(isVisible);
                    }
                } >
                <div className = { `cie_nbanimate  ${className ? className : ""}` } >
                    <div className={`${ !noCard ? 'card' : '' } ${ cardClassName ? cardClassName : '' }`}>
               		    <div className = {`${ !noCard ? 'card-content' : '' }`}  ref = { countUpRef } > </div> 
                    </div>
				</div> 
			</VisibilitySensor>
            )
            } 
		</CountUp>
    );
};
export default NumberAnimate;