import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard"; 

const ClNumbersAnimate2 = () => {
  return (
    <div className="cie_nbanimate cie_focus has-text-weight-bold">
      <div className="card">
        <div className="card-content ">
          <NumberAnimateNocard   nb={ 32.3 } afterNb=" %" decimals={ 1 } prefix="<span class='has-text-weight-normal'>Près de</span>" suffix="<span class='has-text-weight-normal'>ont entre 30 et 49&nbsp;ans</span><span class='color_rubrique size-7 has-text-weight-normal' >26,7 % au niveau national</span>" />
          <NumberAnimateNocard className="mt-5"  nb={ 33.7 } afterNb=" %" decimals={ 1 } prefix="<span class='has-text-weight-normal'>Près de</span>" suffix="<span class='has-text-weight-normal'>ont moins de 30&nbsp;ans</span><span class='color_rubrique size-7 has-text-weight-normal' >35,2 % au niveau national</span>" />
          <NumberAnimateNocard className="mt-5"  nb={ 7.5 } afterNb=" %" decimals={ 1 } prefix="<span class='has-text-weight-normal'>Seuls</span>" suffix="<span class='has-text-weight-normal'>ont plus de 75&nbsp;ans</span><span class='color_rubrique size-7 has-text-weight-normal' >8,5 % au niveau national</span>" />
        </div>
      </div>
    </div>
  )
}
export default ClNumbersAnimate2;