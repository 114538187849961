import React, { useEffect, useState, useContext, lazy } from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactGA from "react-ga";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";


import { PageContext } from "./context/PageContext";
import rubriques from "./datas/rubriques.json";

const Home = lazy(() => import("./views/Home"));
const Page = lazy(() => import("./views/Page"));

const componentRegistry = {
    Home: Home,
    Page: Page,
};

const { REACT_APP_RGPD1, REACT_APP_RGPD2 } = process.env;

const Routes = withRouter(({ location, ...props }) => {

    const [isCookieConsent, setIsCookieConsent] = useState(false);
    const isConsent =  getCookieConsentValue('cookieAccept');
    const { page, updatePage } = useContext(PageContext);
    const path = location?.pathname?.split("/");
    // const currentKey = path[path.length - 1] || "/";
    const timeout = { enter: 500, exit: 100 };

    ReactGA.initialize("UA-101277647-2");
    useEffect(() => {
        isCookieConsent && isCookieConsent === true &&
            setTimeout(() => {


                ReactGA.pageview(location.pathname);
            }, 1000);
    }, [location, isCookieConsent]);

    // Change page context slug on location change
    useEffect(() => {
        const rubrique =
            rubriques?.length > 0
                ?
                rubriques.filter((item) => item.link === location.pathname)
                : null;
        rubrique &&  updatePage({ slug: rubrique[0]?.slug, sectionSlug : null });
    }, [location]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            // behavior: 'smooth',
        });
    }, [location, window]);

    return (
        <>
        <TransitionGroup component="div" className="App">
            <CSSTransition
                key={location && location.pathname}
                timeout={200}
                classNames={`pageTransition`}
                mountOnEnter={true}
                unmountOnExit={false}
            >
            <div>
                <Switch>
                    {
                        rubriques?.length > 0
                            ? rubriques.map((item) => {
                                const { id, link, component } = item;
                                return (
                                    <Route
                                        key={id}
                                        exact
                                        path={link}
                                        component={componentRegistry[component]}
                                    />
                                );
                            })
                            : ""
                    }
                </Switch>
            </div>
            </CSSTransition>
        </TransitionGroup>
        {
            !isConsent ?
                <CookieConsent
                onAccept={() => {
                    setIsCookieConsent(true);
                }}
                location="bottom"
                declineButtonText="Je refuse"
                buttonText="J'accepte"
                cookieName="cookieAccept"
                style={{ background: "#2B373B" }}
                buttonStyle={{
                    background: "#000",
                    color: "white",
                    fontSize: "13px",
                }}
                declineButtonStyle={{
                    background: "#000",
                    color: "white",
                    fontSize: "13px",
                }}
                enableDeclineButton
                expires={150}
            >
                { REACT_APP_RGPD1 }<br />
                <span >{ REACT_APP_RGPD2 }{" "}

                </span>
            </CookieConsent>
            :
                ''
            }
        </>
    );
});
export default Routes;
