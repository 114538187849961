import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard";
import CieText from '../../components/elements/CieText';
const AdsEvolution = () => {
  return (
    <div className=" cie_focus has-text-weight-bold">
      <div className="card ">
        <div className="card-content px-2 has-text-centered">

        <h4 class='color_rubrique  is-size-6 has-text-centered  font-sourceProR mb-3'>Évolution du nombre de bénéficiaires<br/>de la complémentaire santé solidaire</h4>

        <div className="columns is-multiline complementaire_sante_ame__evolution">
          <div className="column is-12 is-6-widescreen">
            <p className='color_rubrique2 has-text-weight-bold pt-5  mb-3 has-text-centered'>Au 31 décembre 2019,<br/>la CPAM de Paris totalisait</p>
            <NumberAnimateNocard className='color_rubrique' nb={ 205102 }  suffix=" <span class='has-text-weight-normal color_rubrique2 font-sourceProR has-text-centered is-size-7 is-uppercase'>bénéficiaires</span>" />
            <CieText text="" text2="<p class='pt-5 color_black has-text-centered'><b class='size-6 bg_rubrique cie_rounded my-2'>158 597</b><br/><span>bénéficiaires de la C2S</span><br/><b class=' size-6 bg_rubrique cie_rounded my-2'>4 433</b><br/><span>de la C2S participative</span><br/><b class='size-6 bg_rubrique cie_rounded my-2'>42 072</b><br/><span>de l’ACS (les contrats ACS souscrits avant le 1<sup>er</sup> novembre 2019 courant jusqu’à leur terme).</span></p>" />
          </div>
          <div className="column is-12 is-6-widescreen">
            <p class='color_rubrique2 has-text-weight-bold pt-5  mb-3 has-text-centered'>Au 31 décembre 2020,<br/>la CPAM de Paris totalisait</p>
            <NumberAnimateNocard className='color_rubrique' nb={ 204444 }  suffix=" <span class='has-text-weight-normal color_rubrique2 font-sourceProR has-text-centered is-uppercase is-size-7'>bénéficiaires</span>" />
            <CieText text="" text2="<p class='pt-5 color_black has-text-centered'><b class='size-6 bg_rubrique cie_rounded my-2'>168 230</b><br/><span>bénéficiaires de la C2S gratuite</span><br/><b class='size-6 bg_rubrique cie_rounded my-2'>33 694</b><br/><span>bénéficiaires de la C2S participative</span><br/><b class='size-6 bg_rubrique cie_rounded my-2'>2 520</b><br/><span>bénéficiaires de l’ACS.</span></p><p class='color_black'><span>Sur les <b >26 261 bénéficiaires</b> ayant validé leur bulletin d’adhésion à la C2S participative, <b>54,61 %</b> ont souscrit leur contrat auprès de la CPAM, contre <b>45,39&nbsp;%</b> auprès d’un organisme complémentaire privé.</span></p>" />
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
export default AdsEvolution;