import React from 'react'
import {
  Modal,
  useModal,
  ModalTransition,
} from 'react-simple-hook-modal';
import 'react-simple-hook-modal/dist/styles.css';
// import './ciemodal.scss';
import UseViewport from '../../../utils/UseViewport';
const { REACT_APP_WIDESCREEN } = process.env;

import Components from '../../../Components';

export default function CieModal({ key, id,  open, content,  component, showOnMobile, modalClassName, className}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <>
        <div key={ key ? `cieModal__${ key}` : 'cieModal' } className="is-clickable" onClick={ (UseViewport()?.width > REACT_APP_WIDESCREEN || showOnMobile) && openModal} dangerouslySetInnerHTML={{ __html:  open }} />
       
        <Modal
          isOpen={isModalOpen}
          onBackdropClick={closeModal}
          transition={ModalTransition.BOTTOM_UP}
          modalClassName={ modalClassName ? modalClassName : 'fffffff'}
          
        >
          { (UseViewport()?.width > REACT_APP_WIDESCREEN || showOnMobile)  && isModalOpen === true && <div className='ciemodal__close_container'><button className={`ciemodal__close modal-close is-large ${ className ? className : ''}`} onClick={closeModal} aria-label="close" ></button> </div> }
          <div id={ id ? id : '' } className={ `${ className}__modal` }>
            
            {
              content && <div dangerouslySetInnerHTML={{ __html: content }} />
            }
            { 
              component?.length > 0 &&
                component.map((c, index2) => (
                    <Components
                        key={`modalcomp_${index2}`}
                        component={c}
                    />
                ))
            
            }
          </div>
           
        </Modal>
          
    </>
  )
}
