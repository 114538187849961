import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard";

const AdsBeneficiaires = () => {
  return (
    <div className="cie_nbanimate  has-text-weight-bold">
      <div className="card">
        <div className="card-content">
          <NumberAnimateNocard  nb={ 49085 } prefix="<div class='cie-icon  mb-3'><i class='icon-ceam-w'></i></div>" suffix=" <span class='has-text-centered has-text-weight-bold'>Bénéficiaires<br/>au 30 septembre 2019</span>" />
          <NumberAnimateNocard className="mt-5"  nb={ 52468 } suffix=" <span class='has-text-centered has-text-weight-bold'>Bénéficiaires<br/>au 30 septembre 2020</span>" />
          <p className="m-0"><span className='color_rubrique2 has-text-centered pt-1-rfs '> Augmentation de près de</span></p>
          <p className="is-inline-flex is-align-items-center has-text-centered"><div className="cie-icon bg_transparent cie_noshadow icon-md m-0"><i className="icon-rise" style={{marginRight: 'auto', marginLeft: 0}}></i> </div> <b className="size-4 color_rubrique2">7 %</b></p>
        </div>
      </div>
    </div>
  )
}
export default AdsBeneficiaires;