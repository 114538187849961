import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import validator from 'validator'
import axios from 'axios';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

const { REACT_APP_API, REACT_APP_EMAILSEND, REACT_APP_GOOGLEMAP, REACT_APP_RGPD3, REACT_APP_RECAPTCHAKEY   } = process.env;


export default function ContactForm() {
  const [emailError, setEmailError] = useState('')
  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Merci de renseigner une adresse valide.')
    }
  }

  const[ fname, setFname ] = useState('');
  const[ lname, setLname ] = useState('');
  const[ mail, setMail ] = useState('');
  const[ message, setMessage ] = useState('');
  const [ mailSent, setMailSent] = useState(false);
  const isConsent =  getCookieConsentValue('cookieAccept');


  const handleFormSubmit = ( e ) => {
    e.preventDefault();
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute(REACT_APP_RECAPTCHAKEY, {action: 'submit'}).then(function(token) {

          axios({
            method: 'post',
            url: `${REACT_APP_API}`,
            headers: { 'content-type': 'application/json' },
            data: {
              token,
              fname,
              lname,
              mail,
              message
            }
          })
            .then(result => {
              setMailSent(result.data.sent);
            })
            .catch(error => {
              console.log(error);
            });
      });
    });
  }

  return (
    <div>
      <h2>Contact</h2>

            <form /* method="post" action="/api/index.php" */ className="columns is-multiline">
              <div className="field column is-6-desktop is-12" >
                <label className="label">Prénom</label>
                <input className="input" type="text" id="fname" name="fname" placeholder="Prénom" onChange={e => setFname(e.target.value)} />
              </div>
              <div className="field column is-6-desktop is-12">
                <label className="label">Nom</label>
                <input className="input" type="text" id="lname" name="lname" placeholder="Nom" onChange={e => setLname(e.target.value)}  />
              </div>
              <div className="field column is-6-desktop is-12">
                <label className="label">Email</label>

                <div className="control  ">
            <input id="mail" name="mail" className="input" required type="email" placeholder="email" onChange={e => { validateEmail(e); setMail(e.target.value); } } />
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{emailError}</span>
                </div>
              </div>
              <div className="field column is-6-desktop is-12">
                <label className="label">Message</label>
                <textarea id="message" name="message" placeholder="Votre message" onChange={e => setMessage( e.target.value )} value={message}className="textarea" > </textarea>

                <p className="is-pulled-right"><input data-action='submit' onClick={e => handleFormSubmit(e)} className="button  mt-5" type="submit" value={ REACT_APP_EMAILSEND } /></p>
              </div>
            </form>

        <div>
          {
            mailSent && <h3 className="has-text-centered color-blue4">Merci pour votre message, nous y répondrons dans les meilleurs délais</h3>
          }
        </div>

        <h2>Adresse</h2>
        <div className="columns">
          <div className="contact__address column is-12 is-6-desktop">
            <p>Assurance Maladie de Paris<br/>
            21 rue Georges Auric<br/>
            75948 Paris cedex 19</p>
          </div>
          <div className="contact__map column is-12 is-6-desktop">
              {
                isConsent && isConsent === "true" ?
                    <div dangerouslySetInnerHTML = {{ __html: REACT_APP_GOOGLEMAP }} />
                :
                <div className="color-red2">{ REACT_APP_RGPD3 }</div>
            }
          </div>
        </div>
    </div>
  );

}
