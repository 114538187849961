import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import 'core-js/es/array';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/styles.scss";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
