import React, { useContext } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { HashLink  } from 'react-router-hash-link';
import { PageContext } from "../../../context/PageContext";
import rubriques from "../../../datas/rubriques.json";

const SubNav = ({ parentLink, handleClose, active, slug }) => {
  const { page } = useContext(PageContext);

  const ShowLink = (props) => {
    const { id, slug, title } = props.item;

    return props?.item ?
      <Link
      dangerouslySetInnerHTML={{ __html: `<span>${title}</span>` }}
       key={`linkSubNav_${id}`}
      to={slug}
      className={`navbar-item-subitem ${page?.slug}`}
      activeClass="active"
      spy
      smooth
      hashSpy
      offset={-20}
      duration={700}
      />
      :
      ''

  }

    return (
        page && page?.slug !== "home"  && (
                rubriques?.length > 0
                    ?
                    rubriques.filter((item) => item.slug === page.slug).map((item, index) => {
                        const { subRubriques } = item;
                        return (
                            subRubriques?.length > 0
                            ? subRubriques.map((item2) => {
                              return <Link
                                  onClick={handleClose}
                                  dangerouslySetInnerHTML={{ __html: `<span>${item2.title}</span>` }}
                                  key={`linkSubNav_${item2.id}`}
                                  to={item2.slug}
                                  className={`navbar-item-subitem ${page?.slug}`}
                                  activeClass="active"
                                  spy
                                  smooth
                                  hashSpy
                                  offset={-20}
                                  duration={700}
                                />

                                /*
                                return (
                                    <Link
                                        key={id}
                                        to={slug}
                                        activeClass="active"
                                        spy
                                        smooth
                                        hashSpy
                                        offset={-100}
                                        duration={700}
                                    >
                                        {title}
                                    </Link>
                                );
                                */

                            })
                            : ""

                        );
                    }
                )
                : ""


        )
    );
};
export default SubNav;
