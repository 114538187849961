import React from "react";
import Swiper from "react-id-swiper";
import "./carousel.scss";

import UseViewport from "../../../../utils/UseViewport";

// Modal
import CieModal from "../../../elements/CieModal";
const { REACT_APP_WIDESCREEN } = process.env;
const Carousel = ({
    key,
    children,
    images,
    desc,
    modal,
    className,
    calculateHeight,
    linkOnClick,
    modalClassName,
    modalOpenText,
    slidesPerView,
    activeSlideKey,
    centeredSlides,
    noPagination,
    paginationTitle,
    paginationTitleMini,
    autoplay,
    prevNextBt,
}) => {

    const params = {
        activeSlideKey: activeSlideKey ? activeSlideKey : null,
        direction: "horizontal",
        mousewheelControl: true,
       // autoHeight: !calculateHeight ? true : false,
        // autoHeight: true,
        // calculateHeight: calculateHeight && calculateHeight === true ? true : false,
      // calculateHeight: true,
        centeredSlides: centeredSlides ? true: false,
        rebuildOnUpdate: true,
        spaceBetween: 0,
        lazy: false,
        loop: true,
        autoplay: autoplay && {
            delay: 5000,
            disableOnInteraction: false,
        },

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: !noPagination ?
                (index, className) => {
                    //
                    if (paginationTitle) {
                        return (
                            '<div class="'+className+'"><span class="pagetitle">'+paginationTitle[index]+'</span><span class="pagetitle_mini">'+ paginationTitleMini[index]+'</span></div>'
                        );
                    } else {
                        return (
                            '<div class="' +
                            className +
                            '"><span>' +
                            (index + 1) +
                            "</span></div>"
                        );
                    }
                }
            : '',
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        renderPrevButton: () =>
            prevNextBt && (
                <button className="swiper-button-prev">&nbsp;</button>
            ),
        renderNextButton: () =>
            prevNextBt  && (
                <button className="swiper-button-next">&nbsp;</button>
            ),
    };
    const handleOnClick = (e) => {
      linkOnClick && window.open(e.currentTarget.getAttribute('data-link'), '_blank');
    }

    return (
        <div  key={ key ? `carousel_${key}` : ''} className={`${ slidesPerView && slidesPerView !== 1 ? 'swiper__multiplePV': ''} ${className ? className : ''}`}>
        <>
            <Swiper {...params} key={key ? key : 'carousel'}  breakpoints={{
                // when window width is >= 640px
                700: {
                    slidesPerView: 1,
                },
                // when window width is >= 768px
                1216: {
                    slidesPerView: slidesPerView,
                }
            }}
            >
                {
                    // Show images
                     images && images?.length > 0 ? images.map((item, index) => (
                        <div  key={`carousel_${ index }`} className='swipper__image'>
                            <img
                                key={index}
                                src={item}
                                alt=""
                            />
                        { desc && <div className="swiper-slide__desc" dangerouslySetInnerHTML = {{ __html: desc[index] }} /> }
                        </div>
                    ))
                    // Show text
                  : children && children.map((item, index) => {

                        return (
                            <div key={index}  >
                                {
                                    className && className === 'home__timeline__carousel' ?
                                        <div className="home_timeline_title">
                                            <div><img class='responsive-image' src={`./img/timeline/${index}.svg`} /></div>
                                            { paginationTitle &&  <h2 >{ paginationTitle[index]}</h2> }
                                        </div>
                                        :
                                        null
                                }

                            {
                              // </div><div onClick= { linkOnClick ? handleOnClick : '' } data-link={linkOnClick ? linkOnClick[index] : null } className={`swipper__text`} dangerouslySetInnerHTML = {{ __html: item }} />
                              linkOnClick ?
                                <a href={linkOnClick[index]} target="_blank"><div className={'swipper__text'} dangerouslySetInnerHTML={{ __html: item }} /></a>
                                    :
                                    <div className={`swipper__text`} dangerouslySetInnerHTML={{ __html: item }} />
                                }

                                    {

                                        modal && modal?.length > 0 ? <CieModal key={ index ? `cie_modal_${ index}`: ''} className={ modalClassName } id={`swipper__modal${ index }`} content={ modal[index]}  open={modalOpenText ? modalOpenText : '' } /> : ''
                                    }
                                </div>

                        )
                    })
                }
            </Swiper>
        </>

        </div>
    );
};
export default Carousel;
