import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard";

const ClOffreConso = () => {
  return(
    <>
    <div className='columns mt-5'>
      <div style={{ zIndex: 1 }} className='column is-text-left cie_lh1 is-12 is-3-widescreen p-0'>
        <NumberAnimateNocard className="color_rubrique2 has-text-left has-text-weight-bold m-0" nb={ 17073 }  />
        <b className='color_rubrique size-5'>PROFESSIONNELS<br/>DE SANTÉ</b><br/>
        <div className='cie-icon icon-pt is-align-items-flex-start color_rubrique2 has-text-weight-bold mt-3'><i class='icon-rise-b'></i>+0,2 %</div></div>

      <div className='column is-12 is-6-widescreen p-0 has-text-centered'>
        <img className="responsive-image offre_consommation__ill" style={{ maxWidth: '350px' }} src='./img/chiffres_cles/demographie5.png' alt='CPAM de Paris' />

      </div>
      <div className='column cie_lh1 is-12 is-3-widescreen p-0'>
        <NumberAnimateNocard className="color_rubrique2 has-text-left has-text-weight-bold m-0" nb={ 7934 }  />
        <b className='color_rubrique size-5'>MÉDECINS</b><br/>
        <div className='cie-icon is-align-items-flex-start icon-pt color_rubrique2 has-text-weight-bold mt-3'>
          <i className='icon-fall-b'></i>-0,3 %
        </div>
      </div>
      <p className='column is-12 has-text-centered size-7'><i>Évolution entre le 1<sup>er</sup> janvier 2020 et le 1<sup>er</sup> janvier 2021</i></p>
    </div>

    </>
  );
}
export default ClOffreConso;