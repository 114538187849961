import React, { useState, createContext } from "react";

export const PageContext = createContext({
    page: null,
    updatePage: (page) => {},
});

export const PageProvider = (props) => {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const [page, setPage] = useState({
        slug: null,
        sectionSlug: null,
    });
    /*
    const getUrl = () => {
        let slug = page?.slug ? `/${page.slug}` : "";
        let sectionSlug = page?.sectionSlug ? `#${page.sectionSlug}` : "";
        return REACT_APP_PUBLIC_URL + slug + sectionSlug;
    };
    */
    const contextValue = {
        page,
        updatePage: setPage,
        // getUrl: getUrl(),
    };

    return (
        <PageContext.Provider value={contextValue}>
            {props.children}
        </PageContext.Provider>
    );
};
