// Components
import CieModal from "./components/elements/CieModal";
import CieColumns from "./components/layout/CieColumns";
import ContactForm from "./components/elements/ContactForm";
import MentionsLegales from "./components/elements/MentionsLegales";
import Table from "./components/elements/Table";
import CieText from "./components/elements/CieText";
import CieCard from "./components/elements/CieCard";
import CieTabs from "./components/elements/CieTabs";
import CieNbPicto from "./components/bits/dataviz/Dataviz/CieNbPicto";

import Carousel from "./components/bits//carousel/Carousel";
import NumberAnimate from "./components/bits/number-animate/NumberAnimate";
import NumberAnimateNocard from "./components/bits/number-animate-nocard/NumberAnimateNocard";

// Pages specifics
//////////////////////////////////////////////
// Chiffres Clés
import ClNumbersAnimate1 from './pages/chiffres_cles/ClNumbersAnimate1';
import ClNumbersAnimate2 from './pages/chiffres_cles/ClNumbersAnimate2';
import ClOffreConso from './pages/chiffres_cles/ClOffreConso';


// Instances
import InOrganigramme from './pages/instances_de_gouvernance/InOrganigramme';
// Accès Droits Soins
import AdsBeneficiaires from './pages/acces_droits_soins/AdsBeneficiaires';
import AdsEvolution from './pages/acces_droits_soins/AdsEvolution';
import AdsConseillers from "./pages/acces_droits_soins/AdsConseillers";
import AdsCentresLinks from "./pages/acces_droits_soins/AdsCentresLinks";


//////////////////////////////////////////////
const componentRegistry = {
    CieModal,
    CieColumns,
    CieCard,
    ContactForm,
    MentionsLegales,
    Table,
    CieText,
    CieTabs,
    Carousel,
    CieNbPicto,
    NumberAnimate,
    NumberAnimateNocard,
    ClNumbersAnimate1,
    ClNumbersAnimate2,
    ClOffreConso,
    AdsBeneficiaires,
    AdsEvolution,
    AdsConseillers,
    AdsCentresLinks,
    InOrganigramme
};

const Components = ({ component }) => {
    const ComponentData = component ? componentRegistry[component?.name] : "";

    let attr = {};

    component &&
        component?.variables?.length > 0 &&
        component?.variables.map((item) => {
            let key = Object.keys(item);
            key && key.length === 1
                ? (attr[key[0]] = item[key])
                : key.map((i) => {
                      attr[i] = item[i];
                  });
        });

    return (
        <>
            {attr.title && (
                <h2 dangerouslySetInnerHTML={{ __html: attr.title }} />
            )}
            <ComponentData {...attr} />
        </>
    );
};
export default Components;
