import React from 'react'
import Components from '../../../Components';

export default function CieColumns({notFull, key, component, className, layoutClassName}) {

  return (
    <div key={ key ? `CieColumns__${ key}` : '' } className={ `article__layout article__layout-columns columns is-multiline ${ notFull ? 'container' :''} ${ layoutClassName ? layoutClassName : '' }`}>
      <div className={`column  ${className[0]}`}>
          { 
              component?.length > 0 && component.filter(col => col.columnNb === 1).map((c, index) => (
                  <Components key={ `CieColumns${index}`} component={c} />
                ))
          }
        </div>
        { 
          className[1] ? 
            <div className={`column ${className[1]}`}>
              { 
                  component?.length > 0 && component.filter(col => col.columnNb === 2).map((c1, index1) => (
                      <Components key={ `CieColumns2${index1}`} component={c1} />
                    ))
              }
            </div>
          :
          ''
        }
        { 
          className[2] ? 
            <div className={`column ${className[2]}`}>
              { 
                component?.length > 0 && component.filter(col => col.columnNb === 3).map((c2, index2) => (
                    <Components key={ `CieColumns3${index2}`} component={c2} />
                  ))
              }
            </div>
          :
          ''
        }
    </div>
  )
}
