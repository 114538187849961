import React, { useState, useEffect } from 'react'
import TypeIt from 'typeit-react';
const { REACT_APP_SHOWMORE, REACT_APP_SHOWLESS } = process.env;

export default function CieText({key, hasMore, text, text2, animation, className, buttonArrow}) {
  const [ showText2, setShowText2 ] = useState(false);
  const [ showText2Label, setShowText2Label ] = useState(REACT_APP_SHOWMORE);
  const handleShowText2 = () => {
    setShowText2( !showText2 );
    setShowText2Label(showText2 === true ? REACT_APP_SHOWMORE : REACT_APP_SHOWLESS)
  }

  const arrowClass = showText2 ? 'bt_up' :'bt_down';
  return (
    <>
      <div className={ className ? className : '' } dangerouslySetInnerHTML = {{ __html: text }} />
      <div className={`cie_text2 ${ text2 && showText2 === true ? 'opened' : 'closed'}`}>{ text2 && showText2 === true ? <div dangerouslySetInnerHTML = {{ __html: text2 }} /> : '' }</div>
      {  text2 ? <button className={`${ buttonArrow ? 'table_container__button cie_radius0 cie_w99 bg_white_imp' : '' } ${ buttonArrow ? arrowClass :''}` } onClick={ handleShowText2 }><span>{ showText2Label }</span></button> : '' } 
  </>
  )
  /*
  return animation && animation[0]?.type === 'typewriter' 
    ?
    <TypeIt
        key= { key ? key : null }
        options={{
          strings: [text],
          speed: 20,
          waitUntilVisible: true
        }}
      />
    : 
      <>
        <div className={ className ? className : '' } dangerouslySetInnerHTML = {{ __html: text }} />
        <div className={`cie_text2 ${ text2 && showText2 === true ? 'opened' : 'closed'}`}>{ text2 && showText2 === true ? <div dangerouslySetInnerHTML = {{ __html: text2 }} /> : '' }</div>
        {  text2 ? <button className={`${ buttonArrow ? 'table_container__button cie_radius0 cie_w99 bg_white_imp' : '' } ${ buttonArrow ? arrowClass :''}` } onClick={ handleShowText2 }><span>{ showText2Label }</span></button> : '' } 
      </>
  */
}
