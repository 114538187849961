import NumberAnimate from "@bit/charlieprod.reactcomponents.number-animate";
 
const { REACT_APP_PUBLIC_URL } = process.env;
const CieNbPicto = ({ key, img, prefix, text, nb}) => {

    // const { img, prefix, text, nb} = data[0]; 
    const _img = img
        ? REACT_APP_PUBLIC_URL + "/img/cienbpicto/" + img
        : null;
  
    return (
        <div className="cie_nbpicto">
            {_img && <img className="responsive-image" alt="" src={_img} />}
            <p className="cie_nbpicto__prefix">{prefix}</p>
            <p className="cie_nbpicto__text"><NumberAnimate key={ key && key }  nb={nb} />{" "}{text}</p>
        </div>
    );
};
export default CieNbPicto;
