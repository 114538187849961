import React from 'react';
import NumberAnimateNocard from "../../components/bits/number-animate-nocard/NumberAnimateNocard"; 

const ClNumbersAnimate1 = () => {
  return (
      <div className="columns paris_et_sa_population mt-5">
        <div className="column is-12 is-4-widescreen">
          <NumberAnimateNocard className="color_rubrique has-text-centered has-text-weight-bold" nb={ 922528 }  suffix="<span class='has-text-weight-normal has-text-centered'>ont moins de 30&nbsp;ans</span>" />
        </div>
        <div className="column is-12 is-4-widescreen">
          <NumberAnimateNocard className="color_rubrique has-text-centered has-text-weight-bold"  nb={ 884216 } suffix="<span class='has-text-weight-normal has-text-centered'>ont entre 30 et 49&nbsp;ans</span>" />
        </div>
        <div className="column is-12 is-4-widescreen">
          <NumberAnimateNocard className="color_rubrique has-text-centered has-text-weight-bold"  nb={ 205630 } suffix="<span class='has-text-weight-normal has-text-centered'>ont plus de 75&nbsp;ans</span>" />
        </div>
    </div>
  )
}
export default ClNumbersAnimate1;