import React from 'react';
import NumberAnimateNocard from "../../bits/number-animate-nocard/NumberAnimateNocard"; 

const CieCard = ({ key, containerClass, cardClass, cardContentClass, numberAnimate, text }) => {
  return (
    <div key={ key ? key : 'cieCard' } className={ containerClass }>
      <div className={ `card ${ cardClass ? cardClass : '' }`}>
        <div className={ `card-content ${ cardContentClass ? cardContentClass : '' }`}>
          {
            text && <div dangerouslySetInnerHTML={{ __html: text }} />
          }
          {  
            numberAnimate ?
              numberAnimate.map((n, index) => {
                const { nb, afterNb, decimals, prefix, suffix  } = n;
                return (
                  <NumberAnimateNocard  key={`cieCard__${index}`} afterNb={ afterNb } decimals={ decimals } nb={ nb } prefix={ prefix ? prefix : '' } suffix={ suffix ? suffix : '' } />
                )
              })
            :
            ''
          }
   
        </div>
      </div>
    </div>
  );
}
export default CieCard;