import React, { Suspense } from "react";
import { Link, BrowserRouter } from "react-router-dom";
import Nav from "./components/layout/Nav";
import Aside from "./components/layout/Aside";
// import SubNav from "./components/layout/SubNav";
import Routes from "./Routes";
import {
  ModalProvider
} from 'react-simple-hook-modal';
// import { ParallaxProvider } from 'react-scroll-parallax';

import Footer from "./components/layout/Footer";
import { PageProvider } from "./context/PageContext";
// import './Main.js';

const { REACT_APP_BASENAME } = process.env;
function App() {
    return (
        <BrowserRouter basename={REACT_APP_BASENAME}>
            <PageProvider>
                <ModalProvider>
                    <Nav />
                    <Aside />
                    <main id="main">
                        <Suspense fallback={<div></div>}>
                            <Routes />
                        </Suspense>
                    </main>
                    <Footer />
                </ModalProvider>
            </PageProvider>
        </BrowserRouter>
    );
}

export default App;
