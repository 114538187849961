import Plx from "react-plx";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Cookies } from "react-cookie-consent";

import CieModal from "../../elements/CieModal";
import datas from "../../../datas/rubriques.json";
import PDFIcon from '../../../assets/img/icons/icon-pdf2.svg';
import sppIcon from '../../../assets/img/icons/icon-social-spp.svg';
import ytIcon from '../../../assets/img/icons/icon-social-yt.svg';
import twIcon from '../../../assets/img/icons/icon-social-tw.svg';
import fbIcon from '../../../assets/img/icons/icon-social-fb.svg';
import inIcon from '../../../assets/img/icons/icon-social-in.svg';

const {
  REACT_APP_BASE_METATITLE2,
  REACT_APP_URL_SANTE,
  REACT_APP_URL_FB,
  REACT_APP_URL_TW,
  REACT_APP_URL_YT,
  REACT_APP_URL_LK
} = process.env;
const parallaxData = [
    {
        start: "90vh",
        end: "100vh",
        properties: [
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            },
        ],
    },
];
const handleModify = () => {
    Cookies.remove('cookieAccept');
    window.location.reload(false);

}
const Footer = () => {
    const date = new Date();
    return (
        <Plx className="parallaxItem" parallaxData={parallaxData}>
            <div className="container-fluid footer">
                <div className="columns container-fluid">
                    <div className="column is-12 is-5-widescreen is-4-fullhd is-flex-widescreen is-justify-content-space-evenly">
                        <h2 className="cie_desktoponly">Rapport<br/>d'activité<br/>2020<span className="is-block cie_lh1 mb-5">CPAM de Paris</span></h2>
                        <div>
                            <a className='has-tooltip-multiline has-tooltip-multiline_nodots has-tooltip-right is-align-items-center is-flex' data-tooltip="Téléchargez le Rapport d'activité CPAM de Paris 2020 (PDF)" target="_blank" href="./Rapport_activite_CPAM-Paris_2020.pdf"><img width="45px" src={PDFIcon} alt='CPAM de Paris / Téléchargez le rapport au format PDF' /><span className="ml-1">Télécharger le PDF (version complète)</span></a>
                            <a className='has-tooltip-multiline has-tooltip-multiline_nodots has-tooltip-right is-align-items-center is-flex' data-tooltip="Téléchargez les Chiffres-Clés CPAM de Paris 2020 (PDF)" target="_blank"  href="./Chiffres-cles_CPAM-Paris_2020.pdf"><img width="45px" src={PDFIcon} alt='CPAM de Paris / Téléchargez les Chiffres-Clés' /><span className="ml-1">Télécharger le PDF (chiffres-clés)</span></a>
                        </div>
                    </div>
                    <div className="footer__rubriques column is-12 is-3-widescreen is-4-fullhd cie_desktoponly">

                        <ul className="nav">
                            {datas &&
                                datas.filter(i => i.slug !== 'home').map((item, index) => {
                                    const { link, title } = item;
                                    return (
                                        <li key={item.id} className="nav-item">
                                            <NavLink
                                                className="nav-link"
                                                to={`${link}`} exact
                                                dangerouslySetInnerHTML = {{ __html: title }} />
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>


                   <div className="footer__socials column is-12 is-2-widescreen my-3-mobile">
                        <ul className="nav-socials">
                            <li className="nav-item"><a rel="noopener noreferrer" target="_blank" href={REACT_APP_URL_SANTE}><img  width="50px" src={sppIcon} alt='CPAM de Paris / Santé Pratique Paris' />Santé Pratique Paris</a></li>
                            <li className="nav-item"><a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_FB }><img  width="20px" src={fbIcon} alt='CPAM de Paris / Facebook' />Facebook</a></li>
                            <li className="nav-item"><a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_TW }><img  width="30px" src={twIcon} alt='CPAM de Paris / Twitter' />Twitter</a></li>
                            <li className="nav-item"><a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_YT }><img  width="35px" src={ytIcon} alt='CPAM de Paris / YouTube' />YouTube</a></li>
                            <li className="nav-item"> <a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_LK }><img  width="30px" src={inIcon} alt='CPAM de Paris / LinkedIn' />LinkedIn</a></li>
                        </ul>
                   </div>
                    <div className="column is-12 is-2-widescreen">
                        <div className="is-fullheight is-flex is-flex-direction-column is-justify-content-space-between">
                            <div>
                                <p><CieModal id="modal_mentions" showOnMobile open="<u>Mentions légales</u>"  component={[{"name": "MentionsLegales"}]} className='home'/></p>
                                <p><CieModal id="modal_contact" showOnMobile open="<u>Contact</u>"  component={[{"name": "ContactForm"}]} className='home' /></p>
                                <p className="cursor_pointer" onClick = { () => handleModify() } ><u>Gestion des cookies</u></p>
                            </div>
                            {
                            /*
                            <div>
                                <p>Assurance Maladie de Paris<br/>
                                21 rue Georges Auric<br/>
                                75948 Paris cedex 19</p>
                                © {date.getFullYear()} / {REACT_APP_BASE_METATITLE2}
                            </div>
                            */
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Plx>
    );
};
export default Footer;
