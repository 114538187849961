import React, { useRef } from 'react'
import { Link } from "react-router";
import PDFIcon from '../../../assets/img/icons/icon-pdf.svg';
import DownloadPDF from '../../../assets/img/download_pdf.jpg';
import DownloadCL from '../../../assets/img/download_cl.jpg';

import LinksIcon from '../../../assets/img/icons/icon-links.svg';

import sppIcon from '../../../assets/img/icons/icon-social-spp.svg';
import ytIcon from '../../../assets/img/icons/icon-social-yt.svg';
import twIcon from '../../../assets/img/icons/icon-social-tw.svg';
import fbIcon from '../../../assets/img/icons/icon-social-fb.svg';
import inIcon from '../../../assets/img/icons/icon-social-in.svg';

const {
  REACT_APP_URL_SANTE,
  REACT_APP_URL_FB,
  REACT_APP_URL_TW,
  REACT_APP_URL_YT,
  REACT_APP_URL_LK
} = process.env;

export default function Aside() {
  const ref = useRef();
  const ref2 = useRef();
  const handleShow = () => {
			ref.current.className = " active"
	}
	const handleHide = () => {
			ref.current.className = " "
	}
  const handleShow2 = () => {
			ref2.current.className = " active"
	}
	const handleHide2 = () => {
			ref2.current.className = " "
	}
  return (
    <aside id="aside">
      <div className="is-flex is-relative">
        <div className="aside__pdf" onMouseEnter={ handleShow } onMouseLeave={ handleHide } >
          <img width="30px" className="cursor_pointer" src={PDFIcon} alt='' />
          <div id="aside__pdf__links"  ref={ ref } >
            <a className='has-tooltip-multiline has-tooltip-left ' data-html="true" data-tooltip="Téléchargez le Rapport d'activité CPAM de Paris 2020 (PDF)" target="_blank" href="./Rapport_activite_CPAM-Paris_2020.pdf"><img src={ DownloadPDF } /></a>
            <a className='has-tooltip-multiline has-tooltip-left ' data-tooltip="Téléchargez les Chiffres-Clés CPAM de Paris 2020 (PDF)" target="_blank" href="./Chiffres-cles_CPAM-Paris_2020.pdf"><img src={ DownloadCL } /></a>
          </div>
        </div>
        <div className="aside__socials" onMouseEnter={ handleShow2 } onMouseLeave={ handleHide2 } >
          <img width="30px" src={LinksIcon} className="cursor_pointer" alt='CPAM de Paris / Réseaux sociaux' />
          <div id="aside__socials__links"  ref={ ref2 } >
            <a rel="noopener noreferrer" target="_blank" href={REACT_APP_URL_SANTE} className='has-tooltip-multiline has-tooltip-left' data-tooltip="Accédez au compte de Santé Pratique Paris"><img  width="50px" src={sppIcon} alt='CPAM de Paris / Site Santé Pratique Paris' /></a>
            <a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_FB } className='has-tooltip-multiline has-tooltip-left' data-tooltip="Accédez au compte Facebook de la CPAM de Paris" data-html="true"><img  width="30px" src={fbIcon} alt='CPAM de Paris / Facebook' /></a>
            <a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_TW } className='has-tooltip-multiline has-tooltip-left' data-tooltip="Accédez au compte Twitter de la CPAM de Paris"><img  width="30px" src={twIcon} alt='CPAM de Paris / Twitter' /></a>
            <a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_YT } className='has-tooltip-multiline has-tooltip-left' data-tooltip="Accédez au compte YouTube de la CPAM de Paris"><img  width="35px" src={ytIcon} alt='CPAM de Paris / YouTube' /></a>
            <a rel="noopener noreferrer" target="_blank" href={ REACT_APP_URL_LK } className='has-tooltip-multiline has-tooltip-left' data-tooltip="Accédez au compte LinkedIn de la CPAM de Paris"><img  width="30px" src={inIcon} alt='CPAM de Paris / LinkedIn' /></a>
          </div>
        </div>


      </div>
    </aside>
  )
}
