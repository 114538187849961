import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageContext } from "../../../context/PageContext";
import rubriques from "../../../datas/rubriques.json";
import UseViewport from '../../../utils/UseViewport';
import UseMousePosition from '../../../utils/UseMousePosition';
import { Debounce }  from '../../../utils/Debounce';
import Logo from "../../../assets/img/logo.svg";
import LogoMin from "../../../assets/img/logo_min.png";
import ImgHome from "../../../assets/img/icons/home.svg";
import ImgBurger from "../../../assets/img/bt_burger.svg";
import ImgBurgerClose from "../../../assets/img/bt_burger_close.svg";
import SubNav from '../SubNav';
const { REACT_APP_WIDESCREEN, REACT_APP_PUBLIC_URL } = process.env;
const screenMin = parseInt(REACT_APP_WIDESCREEN);


const Nav = () => {
    const { page } = useContext(PageContext);
    // const [currentSectionSlug, setCurrentSectionSlug] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { x } = UseMousePosition();
    const screenWidth = UseViewport() ? UseViewport().width : null;
    const isWideScreen = screenWidth > screenMin;

    const handleScroll = Debounce(() => {
        const currentScrollPos = window.pageYOffset;
       // setIsMenuOpen((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70)  || currentScrollPos < 10);
        setIsMenuOpen(false);
        setPrevScrollPos(currentScrollPos);
    }, 100);

    useEffect(() => {
      screenWidth > screenMin && window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    /*
    useEffect(() => {
        console.log('currentSectionSlug', currentSectionSlug, page.sectionSlug)

        currentSectionSlug && currentSectionSlug !== page?.sectionSlug && setIsMenuOpen( true );
        setCurrentSectionSlug(page.sectionSlug);
    }, [page.sectionSlug, currentSectionSlug])
    */
    /*
    useEffect(() => {
       screenWidth && setIsMenuOpen( isWideScreen );
    }, [screenWidth]);
    */
    // Hide Nav (if not home and scroll down after x < 100)
    // Show either

    /*
    useEffect(() => {
        // screenWidth > screenMin && window.addEventListener('scroll', handleScroll);
        !isMenuOpen && x &&  screenWidth > screenMin && setIsMenuOpen(x < 100);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, isMenuOpen, handleScroll, page.sectionSlug]);
    */
  const handleClick = () => {
       setIsMenuOpen(!isMenuOpen);
    };
  const handleClose = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <aside onClick={() => { isWideScreen ? handleClick() : ''}}  id="nav" className={`navbar fixed-top ${ isMenuOpen ? 'active' : ''}`}>
                 <Link onClick={() => { handleClose()}} className="navbar-brand cie_mobileonly" to={`/`}><img  src={ Logo } alt="CPAM de Paris" /></Link>
                 {
                  !isMenuOpen && <Link onClick={() => { handleClose() }} className="navbar-brand cie_desktoponly logo_min" to={`/`}><img  src={ LogoMin } alt="CPAM de Paris" /></Link>
                 }
                <div className="card">
                    <div id="nav__showHide" onClick={() => {
                            handleClick()
                        }}
                    ></div>
                    <div className="card__inner" onClick={() => { handleClose() }}>

                            <Link onClick={() => { handleClose() }} className="navbar-brand cie_desktoponly" to={`/`}><img  src={ Logo } alt="CPAM de Paris" /></Link>
                            {rubriques?.length > 0
                                ? rubriques.filter(item => !item.isNotInMenu).map(({ id, nb, link, slug, title }, index) => {
                                    const className = slug === page?.slug ? "active" : "";
                                    return (
                                      <div className={`nav__container ${className}`}>
                                        <Fragment key={`linMenu${ id}`}  >
                                          <Link
                                              onClick={() => { handleClose() }}
                                              to={link } exact
                                              className={`navbar-item ${className} ${slug}`}
                                              dangerouslySetInnerHTML={{ __html: `<span>${ title }</span>`}}
                                          />
                                          {
                                            slug && slug === page?.slug ? <div className="subnav" ><SubNav handleClose={handleClose} parentLink={link} slug={ slug }  /></div> : ''
                                            // <div className={`subnav ${ className }`} ><SubNav parentLink={link} slug={ slug } active={  slug === page?.slug  }/></div>
                                          }
                                        </Fragment>
                                      </div>
                                    );
                                })
                                : ""}
                                <Link
                                    to={'./annexes'}
                                    key={`linMenuAnnexes`}  onClick={() => { handleClose() }}
                                    className={`navbar-item annexes cie_mobileonly`}
                                    dangerouslySetInnerHTML={{ __html: `<span>Documents utiles</span>`}}
                                />

                    </div>
                </div>
                <div
                    id="nav__burger"
                    className="is-hidden-widescreen"
                    onClick={() =>
                        handleClick()
                    }
                >
                    <img src={isMenuOpen ? ImgBurgerClose : ImgBurger} />
                </div>


            </aside>
        </>
    );
};
export default Nav;
