import React from 'react';
import { Link } from "react-scroll";

const AdsCentresLinks = () => {
  return (
    <div className="centres_links is-flex">
      <div className="">
        <Link 
              key={`linkCentre1`}
              to={`centre1`}
              className='color_rubrique2'
              // className={`navbar-item navbar-item-subitem ${ page?.slug }`}
              smooth
              hashSpy
              offset={-20}
              duration={700}
              dangerouslySetInnerHTML={{ __html: `<div class='cie-icon icon-md is-flex-direction-row is-justify-content-center'><i class='icon-home icon-withshadow'></i><span class='ml-2'>Le centre de santé polyvalent «&nbsp;Réaumur&nbsp;»<br/>(2<sup>e</sup> arrondissement)</span></div>`}}
          />
      </div>
      <div className="ml-5">
        <Link 
              key={`linkCentre2`}
              to={`centre2`}
              className='color_rubrique2'
              // className={`navbar-item navbar-item-subitem ${ page?.slug }`}
              smooth
              hashSpy
              offset={-20}
              duration={700}
              dangerouslySetInnerHTML={{ __html: `<div class='cie-icon icon-md is-flex-direction-row is-justify-content-center'><i class='icon-teeth-r icon-withshadow'></i><span class='ml-2'>Le Centre Dentaire et d’Orthodontie de Paris-CDOP<br/>(11<sup>e</sup> arrondissement)</span></div>`}}
          />
      </div>
    </div>
  )
}
export default AdsCentresLinks;